
import i18n from './i18n';
// 账号效验
const validateAccount = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('loginPage.accountErr')));
  } else {
    callback();
  }
};

// 密码效验
const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('loginPage.passwordErr')));
  } else {
    callback();
  }
};
// 图片验证码效验
const validateCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('loginPage.codeErr')));
  } else {
    callback();
  }
};
export default {
  validateAccount,
  validatePass,
  validateCode
};
