export const menuSide = [
  //  系统管理员
  '/admin-all',
  '/brand-owners-manage',
  '/supplier-management',
  '/investor-management',
  '/finance-supplier-first',
  '/finance-supplier-second',
  '/finance-investor-first',
  '/finance-investor-second',
  '/repayment-list',
  '/repayment-list/repayment-detail',
  '/assets-list',
  '/user-list',
  '/token-list',
  '/mark-list',
  '/bank-area',
  '/bank-area/area-ad',
  '/bank-info',
  '/bank-info/info-ad',
  '/bc-demo'
];
