import Vue from 'vue';
import Router from 'vue-router';
import store from '../plugins/store';
import * as _ from 'lodash';
import routerList from './router-list';
import adminList from './admin-list';
import { menuSide } from './menu-side';

Vue.use(Router);

const router = new Router({
  mode: 'history', // 去掉路由地址的#
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'base',
      redirect: '/login',
      component: () => import('../views/base.vue'),
      children: [
        ...routerList
      ]
    }, {
      path: '/',
      name: 'admin',
      redirect: '/admin-all',
      component: () => import('../views/admin.vue'),
      children: [
        ...adminList
      ]
    }, {
      path: '/login',
      name: 'login',
      // alias: 'login', // 别名
      component: () => import('../views/login-admin.vue')
    },  {
      path: '/reviewPDF',
      name: 'reviewPDF',
      // alias: 'login', // 别名
      component: () => import('../views/reviewPDF.vue')
    }, {
      path: '/login-admin',
      name: 'login-admin',
      // alias: 'login', // 别名
      component: () => import('../views/login-admin.vue')
    },
    {
      path: '/bc-demo',
      name: 'bc-demo',
      // alias: 'login', // 别名
      component: () => import('../views/bc-demo.vue')
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   // alias: 'register',
    //   component: () => import('../views/register.vue')
    // },
    {
      // 处理路由错误
      path: '*',
      component: () => import('../views/page-notfound.vue')
    }
  ]
});

const whiteList = ['/login', '/login-admin', '/bc-demo', '/no-power'];
router.beforeEach((to, from, next) => {
  // 侧边栏展示
  store.dispatch('auth/isSide', menuSide.includes(to.path));

  // 登陆
  if (whiteList.includes(to.path)) {
    return next();
  }

  // 未登陆 跳转 登陆页面
  if (!store.getters['auth/user'] && !whiteList.includes(to.path)) {
    return router.replace('/login');
  }
  // const isConfirm = JSON.parse(localStorage.getItem('isConfirm'));

  const commonPath = routerList.find(item => {
    return item.path === to.path;
  });

  if (commonPath) {
    next();
    return;
  }

  // if (!isConfirm) {
  //   return router.replace('/register');
  // }

  // 投资人 验证路由
  next();
});

export default router;
