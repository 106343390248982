export default {
  // UNIT_MILLION: 1000000, // 入库及合约需要 * ，展现需要 /
  UNIT_MILLION: 1, // 入库及合约需要 * ，展现需要 /
  AUDIT_AMOUNT: 200000, // 入库及合约需要 * ，展现需要 /
  AUTH: {
    UNKNOWN: {
      label: "未认证",
      value: "UNKNOWN",
      valueNew: "0",
    },
    CONFIRM: {
      label: "已认证",
      value: "CONFIRM",
      valueNew: "2",
    },
    PROCESSING: {
      label: "认证中",
      value: "PROCESSING",
      valueNew: "1",
    },
    REJECT: {
      label: "驳回",
      value: "REJECT",
      valueNew: "3",
    },
    UNKNOWNTOO: {
      label: "未认证",
      value: "UNKNOWN",
      valueNew: "9",
    },
  },
  INVESTORTYPE: {
    ORGANIZATION: {
      label: "机构投资人",
      value: "ORGANIZATION",
      valueNew: 1
    },
    PERSONAL: {
      label: "个人",
      value: "PERSONAL",
      valueNew: 2
    }
  },
  EXPERIENCEINVESTMENT: {
    NONE: {
      label: "无",
      value: "NONE",
      valueNew: 1
    },
    ONE_TO_THREE: {
      label: "1-3年",
      value: "ONE_TO_THREE",
      valueNew: 2
    },
    THREE_TO_FIVE: {
      label: "3-5年",
      value: "THREE_TO_FIVE",
      valueNew: 3
    },
    FIVE_TO_TEN: {
      label: "5-10年",
      value: "FIVE_TO_TEN",
      valueNew: 4
    },
    MORE_TEN: {
      label: "10年以上",
      value: "MORE_TEN",
      valueNew: 5
    }
  },
  RISKTOLERANCE: {
    KEEP: {
      label: "保守型",
      value: "KEEP",
      valueNew: 1
    },
    PRUDENT: {
      label: "谨慎型",
      value: "PRUDENT",
      valueNew: 2
    },
    STEADY: {
      label: "稳健型",
      value: "STEADY",
      valueNew: 3
    },
    ACTIVE: {
      label: "积极型",
      value: "ACTIVE",
      valueNew: 4
    },
    RADICAL: {
      label: "激进型",
      value: "RADICAL",
      valueNew: 5
    }
  },
  FINAC: [{ title: 'all', select: true, value: "All" }, { title: 'underApproval', select: false, value: "Approval" }, { title: 'inRevenue', select: false, value: "Fundraising" }, { title: 'financed', select: false, value: "Funded" }, { title: 'repaid', select: false, value: "Repaid" }],
  FINANCSTATUS: [{ title: 'all', select: true, value: "0" }, { title: 'financApproval', select: false, value: "1" }, { title: 'financFundsSent', select: false, value: "2" }, { title: 'financFunded', select: false, value: "3" }, { title: 'financRepaid', select: false, value: "4" }, { title: 'financReject', select: false, value: "5" }],
  CATEGORY: {
    RECHARGE: {
      label: "充值",
      value: "RECHARGE",
      valueNew: "1"
    },
    WITHDRAW: {
      label: "提现",
      value: "WITHDRAW",
      valueNew: "2"
    }
  },
  PROCESS: {
    FIRST: {
      label: "初审",
      value: "FIRST",
      valueNew: "1"
    },
    SECOND: {
      label: "复审",
      value: "SECOND",
      valueNew: "2"
    },
    CONFIRM: {
      label: "通过",
      value: "CONFIRM",
      valueNew: "3"
    },
    REJECT: {
      label: "驳回",
      value: "REJECT",
      valueNew: "4"
    },
  },
  ASSETS_STATUS: [{
    value: '',
    valueNew: '',
    label: 'statusI18n.All'
  }, {
    value: 'FINANCING',
    valueNew: 1,
    label: 'statusI18n.AssetsFundable'
  }, {
    value: 'APPLYING',
    valueNew: 2,
    label: 'statusI18n.AssetsApplying'
  }, {
    value: 'PASS',
    valueNew: 3,
    label: 'statusI18n.AssetsApproved'
  }, {
    value: 'REFUSE',
    valueNew: 4,
    label: 'statusI18n.AssetsRejected'
  }, {
    value: 'EXPIRED',
    valueNew: 9,
    label: 'statusI18n.AssetsExpired'
  }],
  BUSINESS_TYPE: [{
    value: 'RECEIVE_ACCOUNT',
    label: 'statusI18n.AssetsAccountsReceivables'
  }],
  // , {
  //     value: 'RECEIPT',
  //     label: 'statusI18n.AssetsWarehouseReceipt'
  // }],
  REPAY_TYPE: [{
    value: 'EXCHANGE',
    label: 'statusI18n.AssetsBank'
  }, {
    value: 'TICKET',
    label: 'statusI18n.AssetsCommercialBill'
  }, {
    value: 'ELSE',
    label: 'statusI18n.AssetsOthers'
  }],
  USER_ROLE: [{
    value: '1',
    label: 'loginPage.adminRegister'
  }, {
    value: '2',
    label: 'loginPage.financeManagement'
  }, {
    value: '3',
    label: 'loginPage.financeManagementSenior'
  }],
  FEE_TYPE: [{
    value: '',
    valueNew: '',
    label: 'statusI18n.All'
  }, {
    value: '7',
    valueNew: '7',
    label: 'statusI18n.FeeInvestment'
  }, {
    value: '8',
    valueNew: '8',
    label: 'statusI18n.FeeFinancing'
  }],
};
