// 投资人路由 类
'use strict';
export default [
  {
    path: '/admin-all',
    component: () => import('../views/admin/admin-all.vue'),
  },
  {
    path: '/user-list',
    component: () => import('../views/sp-admin/user-list.vue'),
  },
  {
    path: '/token-list',
    component: () => import('../views/sp-admin/token-list.vue'),
  },
  {
    path: '/brand-owners-manage',
    component: () => import('../views/admin/brand-owners-manage.vue'),
  },
  {
    path: '/brand-owners-detail',
    component: () => import('../views/admin/brand-owners-detail.vue'),
  },
  {
    path: '/investor-management',
    component: () => import('../views/admin/investor-management.vue'),
  },
  {
    path: '/investor-detail',
    component: () => import('../views/admin/investor-detail.vue'),
  },
  {
    path: '/supplier-management',
    component: () => import('../views/admin/supplier-management.vue'),
  },
  {
    path: '/supplier-detail',
    component: () => import('../views/admin/supplier-detail.vue'),
  },
  {
    path: '/finance-supplier-first',
    component: () => import('../views/admin/fm-first.vue'),
  },
  {
    path: '/finance-supplier-second',
    component: () => import('../views/admin/fm-second.vue'),
  },
  {
    path: '/finance-investor-first',
    component: () => import('../views/admin/fm-first.vue'),
  },
  {
    path: '/finance-investor-second',
    component: () => import('../views/admin/fm-second.vue'),
  },
  {
    path: '/repayment-list',
    component: () => import('../views/admin/repayment-list.vue'),
  },
  {
    path: '/repayment-list/repayment-detail',
    component: () => import('../views/admin/repayment-detail.vue'),
  },
  {
    path: '/assets-list',
    component: () => import('../views/admin/assets-list.vue'),
  },
  {
    path: '/assets-list/assets-info',
    component: () => import('../views/admin/assets-info.vue'),
  },
  {
    path: '/mark-list',
    component: () => import('../views/admin/mark-list.vue'),
  },
  {
    path: '/mark-list/mark-detail',
    component: () => import('../views/admin/mark-detail.vue'),
  },
  {
    path: '/bank-area',
    component: () => import('../views/admin/bank-area.vue'),
  },
  {
    path: '/bank-area/area-ad',
    component: () => import('../views/admin/bank-area-ad.vue'),
  },
  {
    path: '/bank-info',
    component: () => import('../views/admin/bank-info.vue'),
  },
  {
    path: '/bank-info/info-ad',
    component: () => import('../views/admin/bank-info-ad.vue'),
  }
];
